// external imports
import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// component imports
import DjangoService from "../../APIs/DjangoService";

function SettingsPage() {
  const [XSecurityToken] = useCookies(["XToken"]);
  const [vixData, setVixData] = useState([]);
  const [spyData, setSPYData] = useState([]);
  const effectRef = useRef(true);

  let navigate = useNavigate();

  const [DjangoAuth, setDjangoAuth] = useCookies(["DJToken"]);

  useEffect(() => {
    if (effectRef.current) {
      effectRef.current = false;
      DjangoService.GetVixData(DjangoAuth["DJToken"])
        .then((resp) => setVixData(resp))
        .catch((error) => console.log(error));

      DjangoService.GetSPYData(DjangoAuth["DJToken"])
        .then((resp) => setSPYData(resp))
        .catch((error) => console.log(error));
    }
  }, []);

  // //redirect to login if no user token is found
  useEffect(() => {
    if (!XSecurityToken["XToken"]) {
      navigate("/");
    }
  }, [XSecurityToken, navigate]);

  return (
    <div className="overlay">
      <div className="app"></div>
    </div>
  );
}

export default SettingsPage;
