export default class APIService {
  static localHostRoot = "http://127.0.0.1:8000/";
  static aws_root = "https://volsync.com/api/vol/";

  static current_root = this.aws_root;

  static LoginUser(body) {
    return fetch(`${this.current_root}auth/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static GetVixData(token) {
    return fetch(`${this.current_root}vix-line-graph`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static GetSPYData(token) {
    return fetch(`${this.current_root}spy-data`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static GetTotalSavings(token) {
    return fetch(`${this.current_root}total-savings/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static GetSavings(token) {
    return fetch(`${this.current_root}savings/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static AddFunds(body, token) {
    return fetch(`${this.current_root}savings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp);
  }

  static GetStrategies(token) {
    return fetch(`${this.current_root}strategy/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static UpdateStrategy(body, token, id) {
    return fetch(`${this.current_root}strategy/${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((json) => [json, res.status]));
  }

  static GetStrategyLogs(token) {
    return fetch(`${this.current_root}strategy-logs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static GetExceptionLogs(token) {
    return fetch(`${this.current_root}exception-logs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }


}
