import React, { useContext } from "react";
import "./CaptchaTimeOut.css";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";

function CaptchaTimeOut(props) {
  const {theme} = useContext(ThemeContext)
  const { handleRetry } = props;
  return (
    <>
      <div className="cap-spacer" />
      <div className="cap-center-glass">
        <div className={`cap-glass large ${theme}`}></div>
      </div>
      <div className="cap-q-span cap-error">
        <span>CAPTCHA TIMED OUT</span>
      </div>
      <div className="cap-btn-div">
        <button className={`cap-button-59 ${theme}`} onClick={() => handleRetry()}>
          Retry
        </button>
      </div>
    </>
  );
}

export default CaptchaTimeOut;
