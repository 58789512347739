import React, { useState, cloneElement, useRef, createRef } from "react";
import Portal from "../Portal/Portal";
import "./SettingsToolTip.css";

function SettingsToolTip(props) {
  const {
    placement = "bottom",
    space = 50,
    color = "white",
    backgroundColor = "rgba(246, 52, 102, 0.5)",
    children,
  } = props;
  const [show, setShow] = useState(0);
  const [checked, setChecked] = useState(false);
  const postRef = useRef({ x: 0, y: 0 });
  const btnRef = useRef();
  const tooltipRef = createRef();

  const checker = () => {
    setChecked(!checked);
  };

  function getOffset(el) {
    var x = 0;
    var y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }

    switch (placement) {
      case "top":
        y -= space;
        break;
      case "right":
        x += space;
        break;
      case "left":
        x -= space;
        break;
      default:
        y += space;
        x -= 80;
    }

    return { top: y, left: x };
  }

  const fillerStyles = {
    top: `${postRef.current.top}px`,
    left: `${postRef.current.left}px`,
    color: color,
    backgroundColor: backgroundColor,
  };

  const handleClick = (e) => {
    postRef.current = getOffset(e.currentTarget);
    setShow(!show);
  };

  btnRef.current &&
    btnRef.current.addEventListener("click", () => console.log("clicked"));

  return (
    <>
      {cloneElement(children, {
        onClick: handleClick,
        // onMouse: handleMOut,
      })}
      <Portal
        children={
          <>
            <div
              ref={tooltipRef}
              style={fillerStyles}
              className={show ? "showTip" : "hideTip"}
            >
              <span>booten</span>
              <button type="button" className="one" ref={btnRef}>
                press moi
              </button>
            </div>
          </>
        }
      ></Portal>
    </>
  );
}

export default SettingsToolTip;
