// external imports
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// component imports
import LoginPage from "./Pages/LoginPage/LoginPage";
import TradeSyncPage from "./Pages/TradeSyncPage/TradeSyncPage";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import WithNav from "./Components/GenericComponents/SideBar/WithNav";
import WithThemeSwitch from "./Components/GenericComponents/ThemeSwitch/WithThemeSwitch";
import WithoutNav from "./Components/GenericComponents/SideBar/WithoutNav";
import { ThemeProvider } from "./Components/GenericComponents/ThemeContext/ThemeContext";
import "./App.css";

function App() {
  const [sessionInfo, setSessionInfo] = useState([]);

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route element={<WithThemeSwitch />}>
            <Route element={<WithoutNav />}>
              <Route
                exact
                path="/"
                element={<LoginPage setSessionInfo={setSessionInfo} />}
              />
            </Route>
            <Route element={<WithNav />}>
              <Route exact path="/dash" element={<TradeSyncPage />} />
            </Route>
            <Route element={<WithNav />}>
              <Route exact path="/settings" element={<SettingsPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
