import React from "react";
import Captcha from "./Captcha/Captcha";

function WithCaptcha(props) {
  const {
    showCaptcha,
    childComponent,
    userCaptchaPassed,
    setUserCaptchaPassed,
  } = props;
  return (
    <>
      {showCaptcha? (
        childComponent
      ) : (
        <Captcha
          userCaptchaPassed={userCaptchaPassed}
          setUserCaptchaPassed={setUserCaptchaPassed}
        ></Captcha>
      )}
    </>
  );
}

export default WithCaptcha;
