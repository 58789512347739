// external imports
import React from "react";

// component imports
import FormInput from "../GenericComponents/FormInput/FormInput";
import FormButton from "../GenericComponents/FormButton/FormButton";
import "./LiveTickerForm.css";

function LiveTickerForm(props) {
  const { setNewTicker, newTicker } = props;

  const inputProps = {
    id: "ticker",
    lightbackground: "true",
    name: "ticker",
    type: "text",
    placeholder: "ticker",
    errorMessage: "Ticker should be one to four characters",
    pattern: "^[a-zA-Z0-9^]{1,4}$",
    // label: "Add ticker",
    required: true,
  };

  return (
    <form
      className={"form-transparent"}
      onSubmit={(value) => setNewTicker(value, "create")}
    >
      <FormInput
        {...inputProps}
        value={newTicker.name}
        onChange={(e) => {
          setNewTicker({ ...newTicker, name: e.target.value });
        }}
      />
      <div className="live-ticker-btn-div">
        <button className="live-ticker-btn">Add Ticker</button>
      </div>
    </form>
  );
}

export default LiveTickerForm;
