import React, { useState, useEffect } from "react";
// import LineGraphLive from '../LineGraph/LineGraphLive'
import StrategiesForm from "../../Forms/StrategiesForm/StrategiesForm";


function StrategiesModule() {
  const [newTicker, setNewTicker] = useState({ name: "" });

  return (
    <>
      <StrategiesForm
        setNewTicker={setNewTicker}
        newTicker={newTicker}
      ></StrategiesForm>
    </>
  );
}

export default StrategiesModule;