import React, { useContext } from "react";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";
import CaptchaRespIcon from "../CaptchaRespIcon/CaptchaRespIcon";
import Spinner from "../Spinner/Spinner";

function CaptchaFormResult(props) {
  const {theme} = useContext(ThemeContext)
  const { showPassedPage, responseReceived, userCaptchaPassed, handleRetry } =
    props;

  return (
    <>
      {responseReceived ? (
        <>
          <CaptchaRespIcon pass={showPassedPage}></CaptchaRespIcon>
          <div className="cap-spacer" />
          {showPassedPage ? (
            <>
              <div className="cap-q-span cap-success">
                <span>CAPTCHA PASSED</span>
              </div>
              <div className="cap-btn-div"></div>
            </>
          ) : (
            <>
              <div className="cap-q-span cap-error">
                <span>CAPTCHA FAILED</span>
              </div>
              <div className="cap-btn-div">
                <button className={`cap-button-59 ${theme}`} onClick={() => handleRetry()}>
                  Retry
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <Spinner></Spinner>
      )}
    </>
  );
}

export default CaptchaFormResult;
