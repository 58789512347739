import React, { useState, useEffect } from "react";
// import LineGraphLive from '../LineGraph/LineGraphLive'
import LiveTickerForm from "../../Forms/LiveTickerForm";

function LiveChartModule() {
  const [newTicker, setNewTicker] = useState({ name: "" });

  return (
    <>
      <LiveTickerForm
        setNewTicker={setNewTicker}
        newTicker={newTicker}
      ></LiveTickerForm>
    </>
  );
}

export default LiveChartModule;
