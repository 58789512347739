export default class IGService {
  static igRoot = "https://api.ig.com/gateway/deal/";

  static GetEncryptionKey(apikey) {
    return fetch(`${this.igRoot}session/encryptionKey`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IG-API-KEY": apikey,
      },
    }).then((resp) => resp.json());
  }

  static GetIgAccounts(apikey, cst, sectoken) {
    return fetch(`${this.igRoot}accounts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IG-API-KEY": apikey,
        Version: "1",
        "X-SECURITY-TOKEN": sectoken,
        CST: cst,
      },
    }).then((res) => res.json());
  }

  static GetOpenPositions(apikey, cst, sectoken) {
    return fetch(`${this.igRoot}positions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IG-API-KEY": apikey,
        Version: "2",
        "X-SECURITY-TOKEN": sectoken,
        CST: cst,
      },
    }).then((res) => res.json());
  }

  static CreatePosition(apikey, body, cst, sectoken) {
    return fetch(`${this.igRoot}positions/otc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IG-API-KEY": apikey,
        Version: "2",
        "X-SECURITY-TOKEN": sectoken,
        CST: cst,
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());
  }

  static GetVIX(apikey, cst, sectoken) {
    return fetch(`${this.igRoot}markets/CC.D.VIX.UMP.IP`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IG-API-KEY": apikey,
        Version: "3",
        "X-SECURITY-TOKEN": sectoken,
        CST: cst,
      },
    }).then((res) => res.json());
  }

  static CreateSession(username, password, apikey) {
    return fetch(`${this.igRoot}session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IG-API-KEY": apikey,
        Version: "2",
      },
      body: JSON.stringify({
        identifier: username,
        password: password,
        encryptedPassword: true,
      }),
    }).then((res) => res.json().then((json) => [json, res.headers]));
  }
}
