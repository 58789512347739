// external imports
import React, { useMemo, useContext} from "react";

// component imports
import "./LogDisplay.css";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";

function LogDisplay(props) {
  const {tableData, itemsPerPage=10} = props
  const {theme} = useContext(ThemeContext)

  const getHeaders = (tableData) => {
    return Object.keys(tableData[0])
  }

  const getRows = (tableData) => {
    return tableData
  }

  const headers = useMemo(() => getHeaders(tableData), [tableData]);
  const rows = useMemo(() => getRows(tableData), [tableData]);


  return (
    headers && rows && <table className={`log-table ${theme}`}>
      <tr className={`log-tr ${theme}`}>
      {headers.map((header) => 
        {return (<th className={`log-th ${theme}`}>{header}</th>)}
      )}
      </tr>
      {rows.map((row) =>
      {return <tr className={`log-tr ${theme}`}>
        {Object.values(row).map((cell) => 
        {return (<td className={`log-td ${theme}`}>{cell}</td>)}
        )}
      </tr>
      }
      )}
  </table>
  );
}

export default LogDisplay;
