// external imports
import React, { useEffect, useState, useRef, useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// component imports
import DjangoService from "../../APIs/DjangoService";
import LineGraph from "../../Components/TradeSyncComponents/LineGraph/LineGraph";
import WithDraggable from "../../Components/GenericComponents/DraggableDiv/WithDraggable";
import "./TradeSyncPage.css";
import Console from "../../Components/TradeSyncComponents/Console/Console";
// import Carousel from "../../Components/StockPageComponents/StockCarousel/StockCarousel";
import { ThemeContext } from "../../Components/GenericComponents/ThemeContext/ThemeContext";
import LineGraphLive from "../../Components/TradeSyncComponents/LineGraphLive/LineGraphLive";
import StrategyDisplay from "../../Components/TradeSyncComponents/StrategyDisplay/StrategyDisplay";
import LogDisplay from "../../Components/TradeSyncComponents/LogDisplay/LogDisplay";

function TradeSyncPage() {
  const [XSecurityToken] = useCookies(["XToken"]);
  const [DjangoAuth, setDjangoAuth] = useCookies(["DJToken"]);
  const [vixData, setVixData] = useState([]);
  const [spyData, setSPYData] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [strategyLogs, setStrategyLogs] = useState([]);
  const [exceptionLogs, setExceptionLogs] = useState([]);
  const effectRef = useRef(true);
  const containerRef = useRef(null)
  const { theme } = useContext(ThemeContext);

  let navigate = useNavigate();

  useEffect(() => {
    if (effectRef.current) {
      effectRef.current = false;
      DjangoService.GetVixData(DjangoAuth["DJToken"])
        .then((resp) => setVixData(resp))
        .catch((error) => console.log(error));

      DjangoService.GetStrategies(DjangoAuth["DJToken"])
      .then((resp) => setStrategies(resp))
      .catch((error) => console.log(error));

      DjangoService.GetStrategyLogs(DjangoAuth["DJToken"])
      .then((resp) => setStrategyLogs(resp))
      .catch((error) => console.log(error));

      DjangoService.GetExceptionLogs(DjangoAuth["DJToken"])
      .then((resp) => setExceptionLogs(resp))
      .catch((error) => console.log(error));
    }
  }, []);

  // //redirect to login if no user token is found
  useEffect(() => {
    if (!XSecurityToken["XToken"]) {
      navigate("/");
    }
  }, [XSecurityToken, navigate]);

  return (
    <div className={`overlay ${theme}`}>
      <div className={`app shadow-container ${theme}`}>
        <div className={`left-container ${theme}`}>
          <Console></Console>
        </div>
        <div className={`right-container ${theme}`}>
        <div ref={containerRef} className="trade-container">
          {Object.keys(vixData).length > 0 && containerRef && (
              <WithDraggable
              resizeable={true}
              containerRef={containerRef}
                childComponent={
                  <LineGraph
                    data={vixData}
                    title={"VIX"}
                    granularity={1}
                    maxDataLen={80}
                    yAxisDecimalPlaces={2}
                  ></LineGraph>
                }
              />
            )}
          {Object.keys(strategies).length > 0 && containerRef && (
            <WithDraggable
            resizeable={false}
            containerRef={containerRef}
                  childComponent={
                    <StrategyDisplay
                    strategies={strategies}
                    setStrategies={setStrategies}>
                    </StrategyDisplay>
                  }
            />
          )}

          {Object.keys(exceptionLogs).length > 0 && containerRef && (
            <WithDraggable
            resizeable={false}
            containerRef={containerRef}
                  childComponent={
                    <LogDisplay
                    tableData={exceptionLogs}
                    >
                    </LogDisplay>
                  }
            />
          )}

          {Object.keys(strategyLogs).length > 0 && containerRef && (
            <WithDraggable
            resizeable={false}
            containerRef={containerRef}
                  childComponent={
                    <LogDisplay
                    tableData={strategyLogs}
                    >
                    </LogDisplay>
                  }
            />
          )}
        </div>
        </div>

      </div>
    </div>
  );
}

export default TradeSyncPage;
