import React, {useState, useEffect, useContext} from 'react'
import { ThemeContext } from '../../GenericComponents/ThemeContext/ThemeContext';
import "./StrategyDisplay.css"

function StrategyDisplay(props){

const { strategies,  setStrategies, scale} = props;
const [printData, setPrintData] = useState([]);
const {theme} = useContext(ThemeContext)
const [size, setSize] = useState({"fontSize":`${scale}px`})

useEffect(() => {
    setSize({"fontSize" : `${scale}px`})
  },[scale])


useEffect(() => {
    var data = []

    for (let i = 0; i < strategies.length; i++) {
        data.push(" ",
        `---------------- ${strategies[i].name} ----------------`,
        " ",
        `${"id"} - ${strategies[i].id}`,
        `${"ticker"} - ${strategies[i].ticker}`,
        `${"open position"} - ${strategies[i].open_position}`,
        `${"strategy running"} - ${strategies[i].strategy_running}`,
        `${"buy signal desc"} - ${strategies[i].buy_signal_desc }`,
        `${"sell signal desc"} - ${strategies[i].sell_signal_desc}`
        )

        let arrData = Object.entries(strategies[i].additional_signal_parameters).map(([k,v]) => {
          return (`${k} - ${v}, `)
        })
        arrData.unshift("Extra args - ")
        data.push(arrData)
    }
    setPrintData(data)

  }, [strategies]);

  return (
    <div className={`strategy-container ${theme}`} style={size}>{
    printData.length > 0 && (printData.map((item, index) => {
        return(<div key={index}>{item}</div>)
    }))}
    </div>
  )
}

export default StrategyDisplay