import React, { useContext, useState } from "react";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";
import "./CaptchaFormInput.css";

function CaptchaFormInput(props) {
  const { label, errorMessage, onChange, id, ...inputProps } = props;
  const {theme} = useContext(ThemeContext)
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <div className={"cap-light-form-input"}>
      <label className="cap-input-label">{label}</label>
      <input
        {...inputProps}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={handleFocus}
        focused={focused.toString()}
        className={`cap-input ${theme}`}
      />
      <span className="span-errors">{errorMessage}</span>
    </div>
  );
}

export default CaptchaFormInput;
