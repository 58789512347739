import React, { useContext } from "react";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";
import "./Spinner.css";

function Spinner() {
  const {theme} = useContext(ThemeContext)
  return (
    <div className={`lds-spinner ${theme}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;
