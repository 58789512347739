// external imports
import React, { useState, useContext, useEffect } from "react";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import { useCookies, removeCookie } from "react-cookie";
import { ThemeContext } from "../ThemeContext/ThemeContext";

// component imports
import "./SideBar.css";

function Sidebar() {
  const [DjangoAuth, setDjangoAuth, removeDjangoAuth] = useCookies(["DJToken"]);
  const [XSecurityToken, setXSecurityToken, removeXSecToken] = useCookies([
    "XToken",
  ]);
  const [CSTToken, setCSTToken, removeCSTToken] = useCookies(["CST"]);
  const [sideBar, setSideBar] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [infoColor, setInfoColour] = useState("#F7A90D");
  const [exitColor, setExitColour] = useState("#F7A90D");
  const [closeBarColor, setCloseBarcolor] = useState("#F7A90D");
  const [iconTheme, setIconTheme] = useState({
    primary: "#F7A90D",
    secondary: "#f63466",
  });
  const { theme } = useContext(ThemeContext);
  const showSideBar = () => setSideBar(!sideBar);

  const infoHover = () => {
    setInfoColour(iconTheme.secondary);
    setOpen(true);
  };
  const infoHoverOut = () => {
    setInfoColour(iconTheme.primary);
    setOpen(false);
  };
  const exitHover = () => {
    setExitColour(iconTheme.secondary);
  };
  const exitHoverOut = () => {
    setExitColour(iconTheme.primary);
  };
  const closeHover = () => {
    setCloseBarcolor(iconTheme.secondary);
  };
  const closeHoverOut = () => {
    setCloseBarcolor(iconTheme.primary);
  };

  useEffect(() => {
    switch (theme) {
      case "light":
        setIconTheme({ primary: "#F7A90D", secondary: "#f63466" });
        break;
      case "dark":
        setIconTheme({ primary: "#F7A90D", secondary: "#fff" });
        break;
      default:
        setIconTheme({ primary: "#F7A90D", secondary: "#f63466" });
    }
  }, [theme]);

  //on logout remove token
  const logOutUser = () => {
    removeDjangoAuth(["DJToken"]);
    removeXSecToken(["XToken"]);
    removeCSTToken(["CST"]);
  };

  return (
    <>
      <div className={`navbar ${theme}`}>
        <div
          className={`sidebar-icon open-sidebar-icon ${theme}`}
          onMouseOver={infoHover}
          onMouseOut={infoHoverOut}
          onClick={() => {
            showSideBar();
          }}
        >
          <FaIcons.FaChevronRight
            className={`icon ${!isOpen && "open"}`}
            color={infoColor}
          />
        </div>
        <div
          className={`sidebar-icon logout-icon ${theme}`}
          onMouseOver={exitHover}
          onMouseOut={exitHoverOut}
        >
          <RiIcons.RiLogoutBoxRLine
            onClick={(e) => logOutUser(e)}
            color={exitColor}
          />
        </div>
      </div>
      <nav
        className={sideBar ? `nav-menu active ${theme}` : `nav-menu  ${theme}`}
      >
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <div
              className={`sidebar-icon close-sidebar-icon ${theme}`}
              onMouseOver={closeHover}
              onMouseOut={closeHoverOut}
              onClick={showSideBar}
            >
              <AiIcons.AiOutlineClose color={closeBarColor} />
            </div>
          </li>
          <li className="navbar-toggle">
            <div className="menu-bar"></div>
          </li>
          <li>
            <div className="menu-bar-text"></div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
