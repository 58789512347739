import React, { useContext, useEffect, useState } from "react";
import LiveChartModule from "../LiveChartModule/LiveChartModule";

import "./Console.css";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";
import StrategiesModule from "../StartegiesModule.js/StrategiesModule";

function Console() {
  const { theme } = useContext(ThemeContext);
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className={`console-container ${theme}`}>
      <div className="bloc-tabs">
        <button
          className={
            toggleState === 1 ? `tabs active-tabs ${theme}` : `tabs ${theme}`
          }
          onClick={() => toggleTab(1)}
        >
          Live Tickers
        </button>
        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <LiveChartModule></LiveChartModule>
          </div>
        </div>
        <button
          className={
            toggleState === 2 ? `tabs active-tabs ${theme}` : `tabs ${theme}`
          }
          onClick={() => toggleTab(2)}
        >
          Historic Data
        </button>
        <div className="content-tabs">
          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          ></div>
        </div>
        <button
          className={
            toggleState === 3 ? `tabs active-tabs ${theme}` : `tabs ${theme}`
          }
          onClick={() => toggleTab(3)}
        >
          Trades
        </button>
        <div className="content-tabs">
          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          ></div>
        </div>
        <button
          className={
            toggleState === 4 ? `tabs active-tabs ${theme}` : `tabs ${theme}`
          }
          onClick={() => toggleTab(4)}
        >
          Strategies
        </button>
        <div className="content-tabs">
          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <StrategiesModule></StrategiesModule>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Console;
