  export default class APIService {
    static localHostRoot = "http://127.0.0.1:8000/api/captcha";
    static aws_root = "https://volsync.com/api/captcha";
  
    static current_root = this.aws_root;

    static GetCaptcha() {
        return fetch(`${this.current_root}/get-captcha/`, {
          method: "GET",
          headers: {
            "Content-Type": "image/",
          },
        }).then((res) =>
          res
            .blob()
            .then((blob) => [
              blob,
              res.headers.get("Content-Disposition").split("filename=")[1],
            ])
        );
      }
    
      static CompareCaptcha(body) {
        return fetch(`${this.current_root}/compare-captcha/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }).then((res) => res.json().then((body) => [body, res.status]));
      }
    
}