import React, { useState, useEffect, useRef, useContext } from "react";

import APIService from "../CaptchaService";
import CaptchaForm from "../CaptchaForm/CaptchaForm";
import CaptchaTimeOut from "../CaptchaTimeOut/CaptchaTimeOut";
import CaptchaFormResult from "../CaptchaFormResult/CaptchaFormResult";
import CaptchaRobot from "../CaptchaRobot/CaptchaRobot";
import CaptchaCountdown from "../CaptchaCountdown/CaptchaCountdown";
import CaptchaInfo from "../CaptchaInfo/CaptchaInfo";
import "./Captcha.css";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";

function Captcha(props) {
  const timerRef = useRef(null);
  const passedRef = useRef(null);
  const {theme} = useContext(ThemeContext)
  const { userCaptchaPassed, setUserCaptchaPassed } = props;
  const [responseSubmitted, setResponseSubmitted] = useState("");
  const [responseReceived, setResponseReceived] = useState("");
  const [notARobotComplete, setNotARobotComplete] = useState("");
  const [showPassedPage, setShowPassedPage] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [timeout, setTimeoutVal] = useState(false);
  const [values, setValues] = useState({
    key: "",
    answer: "",
  });

  const getCaptcha = () => {
    APIService.GetCaptcha().then((res) => {
      setCaptcha(URL.createObjectURL(res[0]));
      setValues({ ...values, key: res[1] });
      timerRef.current = setTimeout(() => {
        setTimeoutVal(true);
      }, 30000);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setResponseSubmitted(true);
    let lowerCaseData = {
      key: values.key,
      answer: values.answer.toLowerCase(),
    };
    APIService.CompareCaptcha(lowerCaseData).then((res) => {
      if (res[1] === 200) {
        setShowPassedPage(true);
        passedRef.current = setTimeout(() => {
          setUserCaptchaPassed(true);
        }, 1000);
        setResponseReceived(true);
      } else {
        setUserCaptchaPassed(false);
        setResponseReceived(true);
      }
    });
    clearTimeout(timerRef.current);
    clearTimeout(passedRef.current);
    setValues({ key: "", answer: "" });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleRetry = () => {
    setNotARobotComplete(false);
    setResponseSubmitted(false);
    setResponseReceived(false);
    setUserCaptchaPassed(null);
    setCaptcha("");
    if (timeout) {
      setTimeoutVal(false);
    }
  };

  return (
    <>
      <div className="captcha">
        <form
          onSubmit={handleSubmit}
          className={
            !timeout && !responseSubmitted ? `cap-form-progress ${theme}` : `cap-form ${theme}`
          }
        >
          {/* {notARobotComplete && !timeout && !responseSubmitted && captcha && (
            // <CaptchaCountdown />
          )} */}
          {notARobotComplete ? (
            <>
              {timeout ? (
                <>
                  <CaptchaTimeOut handleRetry={handleRetry}></CaptchaTimeOut>
                </>
              ) : responseSubmitted ? (
                <CaptchaFormResult
                  showPassedPage={showPassedPage}
                  responseReceived={responseReceived}
                  handleRetry={handleRetry}
                  userCaptchaPassed={userCaptchaPassed}
                ></CaptchaFormResult>
              ) : (
                <CaptchaForm
                  captcha={captcha}
                  values={values}
                  onChange={onChange}
                ></CaptchaForm>
              )}
            </>
          ) : (
            <CaptchaRobot
              setNotARobotComplete={setNotARobotComplete}
              getCaptcha={getCaptcha}
            />
          )}
          {!timeout && !responseSubmitted && <CaptchaInfo />}
        </form>
      </div>
    </>
  );
}

export default Captcha;
