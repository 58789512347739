// external imports
import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import pidcrypt from "pidcrypt";
import {
  toByteArray,
  decodeBase64,
  encodeBase64,
  convertFromHex,
} from "pidcrypt/pidcrypt_util";
import "pidcrypt/asn1";
import "pidcrypt/rsa";
import * as IoIcons from "react-icons/io";
// component imports
import DjangoService from "../../APIs/DjangoService";
import IGService from "../../APIs/IGService";
import LoginForm from "../../Components/LoginComponents/LoginForm/LoginForm";
import "./LoginPage.css";
import LoginGraph from "../../Components/LoginComponents/LoginGraph/LoginGraph";
import SettingsToolTip from "../../Components/GenericComponents/SettingsToolTip/SettingsToolTip";
import { ThemeContext } from "../../Components/GenericComponents/ThemeContext/ThemeContext";
import WithCaptcha from "../../Components/Captcha/WithCaptcha";

function LoginPage(props) {
  const [CSTToken, setCSTToken] = useCookies(["CST"]);
  const [XSecurityToken, setXSecurityToken] = useCookies(["XToken"]);
  const [DjangoAuth, setDjangoAuth] = useCookies(["DJToken"]);
  const [settingsColor, setSettingsColour] = useState("#F7A90D");
  const [userCaptchaPassed, setUserCaptchaPassed] = useState(false);
  const [iconTheme, setIconTheme] = useState({
    primary: "#F7A90D",
    secondary: "#F7A90D",
  });
  const [isOpen, setOpen] = useState(false);
  const settingsHover = () => {
    setSettingsColour(iconTheme.secondary);
    setOpen(true);
  };
  const settingsHoverOut = () => {
    setSettingsColour(iconTheme.primary);
    setOpen(false);
  };
  const { RSA, ASN1 } = pidcrypt;
  const [openSettings, setOpenSettings] = useState(false);
  const [sliderValue, setSliderVal] = useState({ sliderPos: 1000, speed: 100 });
  const [pauseGraph, setPauseGraph] = useState(false);
  const { theme } = useContext(ThemeContext);

  let navigate = useNavigate();

  useEffect(() => {
    if (XSecurityToken["XToken"] !== "null" && !!XSecurityToken["XToken"]) {
      navigate("/dash");
    }
  }, [navigate, XSecurityToken]);

  useEffect(() => {
    switch (theme) {
      case "light":
        setIconTheme({ primary: "#F7A90D", secondary: "#f63466" });
        break;
      case "dark":
        setIconTheme({ primary: "#F7A90D", secondary: "#fff" });
        break;
      default:
        setIconTheme({ primary: "#F7A90D", secondary: "#f63466" });
    }
  }, [theme]);

  //login user
  const loginUser = (values) => {
    IGService.GetEncryptionKey(values.apikey)
      .then((resp) => {
        const encryptedPassword = publicEncrypt(
          resp["encryptionKey"],
          `${values.password}|${resp["timeStamp"]}`
        );
        IGService.CreateSession(
          values.username,
          encryptedPassword,
          values.apikey
        ).then((resp) => {
          setCSTToken("CST", resp[1].get("CST"));
          setXSecurityToken("XToken", resp[1].get("X-SECURITY-TOKEN"));
          props.setSessionInfo(resp[0]);
        });

        DjangoService.LoginUser(values)
          .then((resp) => setDjangoAuth("DJToken", resp.token))
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const publicEncrypt = (key, value) => {
    const asn = ASN1.decode(toByteArray(decodeBase64(key)));
    const rsa = new RSA();
    rsa.setPublicKeyFromASN(asn.toHexTree());
    return encodeBase64(convertFromHex(rsa.encrypt(value)));
  };

  const sliderInput = (val) => {
    // sliderRef.current = val.currentTarget.value
    let max = 1000;
    let min = 100;
    let calcSpeed = max + min - val.currentTarget.value;
    setSliderVal({ sliderPos: val.currentTarget.value, speed: calcSpeed });
  };

  return (
    <div className="overlay">
      <div className={`login-left-container ${theme}`}>
        <div className="login-settings">
          <div
            className={`settings-icon open-settings-icon ${theme}`}
            onMouseOver={settingsHover}
            onMouseOut={settingsHoverOut}
            onClick={() => {
              setOpenSettings(!openSettings);
            }}
          >
            <IoIcons.IoMdSettings
              className={`settings-icon ${!isOpen && "open"}`}
              color={settingsColor}
            />
          </div>

          {/* {openSettings &&  */}
          <div
            className={`settings-bar ${
              openSettings ? "show" : "hide"
            } ${theme}`}
          >
            <div>
              <span>Speed control</span>
            </div>
            <input
              className="speed-slider"
              type="range"
              min="100"
              max="1000"
              value={sliderValue.sliderPos}
              onInput={(val) => sliderInput(val)}
            ></input>
            <button
              className={`mute-graph-btn ${theme}`}
              onClick={() => {
                setPauseGraph(!pauseGraph);
              }}
            >
              {pauseGraph ? "Start" : "Stop"}
            </button>
          </div>
          {/* } */}
        </div>
        <LoginGraph speed={sliderValue.speed} pauseGraph={pauseGraph} />
      </div>
      <div className={`login-right-container ${theme}`}>
        <div className="login">
          <WithCaptcha
            showCaptcha={userCaptchaPassed}
            userCaptchaPassed={userCaptchaPassed}
            setUserCaptchaPassed={setUserCaptchaPassed}
            childComponent={ 
              <LoginForm loginUser={loginUser}></LoginForm>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
