// external imports
import React, { useEffect, useState, useRef, useContext } from "react";

// component imports
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";
import "./LoginGraph.css";

function LoginGraph(props) {
  const { speed, pauseGraph } = props;
  const timerRef = useRef(null);
  const [data, setData] = useState({ series: [] });
  const granularity = 1;
  const maxDataLen = 80;
  const yAxisDecimalPlaces = 2;
  const maxFrameSize = "?";
  const { theme } = useContext(ThemeContext);

  // if the new number is greater than the max in the array or lower than the min in the array
  // set the mins or maxes to ""
  //
  // possibly rmeove the inital array fill
  // fill space between points

  const [graphArray, setGraphArray] = useState([]);

  useEffect(() => {
    if (!pauseGraph) {
      if (data.series.length > 0) {
        createChart(data);
      }
      timerRef.current = setTimeout(() => {
        generateData();
      }, speed);
    }
  }, [data, pauseGraph]);

  const generateData = () => {
    if (data.series.length === 0) {
      let firstDataPoint = Math.floor(Math.random() * 50 + 25);
      setData((data) => ({ series: [...data.series, { x: firstDataPoint }] }));
    } else {
      let lastPoint = data.series[data.series.length - 1]["x"];
      let lowerBound = lastPoint - 3;
      let upperBound = lastPoint + 3;
      let newDataPoint = Math.floor(
        Math.random() * (upperBound - lowerBound + 1) + lowerBound
      );
      setData((data) => ({ series: [...data.series, { x: newDataPoint }] }));
    }
  };

  const createChart = (chartData) => {
    var series = chartData.series.slice(-maxDataLen);
    var serieslen = series.length;

    // if (serieslen < maxDataLen) {
    //   let starterData = Array(maxDataLen - serieslen).fill(series[0]);
    //   series = starterData.concat(series);
    //   serieslen = series.length;
    // }

    let min = series.reduce(function (prev, curr) {
      return prev.x < curr.x ? prev : curr;
    });

    let max = series.reduce(function (prev, curr) {
      return prev.x > curr.x ? prev : curr;
    });

    let xAxis = [];

    for (let i = max.x; i >= min.x; i -= granularity) {
      xAxis = xAxis.concat(i.toFixed(yAxisDecimalPlaces));
    }

    let maxLength = String(max.x).length;

    let graphDataHolder = xAxis.map((item) => {
      return { xAxis: item, arr: " ".repeat(serieslen).split("") };
    });

    let graphLine = [];

    for (let i = 0; i < serieslen; i++) {
      let plotPoint = series[i].x;

      let closest = graphDataHolder.reduce((a, b) => {
        return Math.abs(b.xAxis - plotPoint) < Math.abs(a.xAxis - plotPoint)
          ? b
          : a;
      });

      let closestValIndex = graphDataHolder.indexOf(closest);

      graphDataHolder[closestValIndex].arr[i] = "o";
    }

    graphDataHolder.map((item) => {
      let arrayToString = item.arr.join("");
      graphLine.push(arrayToString);
    });

    setGraphArray([...graphLine]);
  };

  return (
    <>
      <div className={`login-graph ${theme}`}>
        {data.series.length > 0 &&
          graphArray.map((item, index) => {
            return (
              <pre>
                <div key={index}>{item}</div>
              </pre>
            );
          })}
      </div>
    </>
  );
}

export default LoginGraph;
