// external imports
import React, { useContext, useEffect, useState } from "react";

// component imports
import "./LineGraph.css";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";

function LineGraph(props) {
  const { data, title, granularity, maxDataLen, yAxisDecimalPlaces, scale} = props;
  const {theme} = useContext(ThemeContext)
  const [size, setSize] = useState({"fontSize":`${scale}px`})

  const [graphArray, setGraphArray] = useState([]);

  useEffect(() => {
    createChart(data);
  }, [data]);

  useEffect(() => {
    setSize({"fontSize" : `${scale}px`})
  },[scale])

  const createChart = (chartData) => {
    var series = chartData.series.slice(-maxDataLen);
    var serieslen = series.length;

    if (serieslen < maxDataLen) {
      let starterData = Array(maxDataLen - serieslen).fill(series[0]);
      series = starterData.concat(series);
      serieslen = series.length;
    }

    let min = series.reduce(function (prev, curr) {
      return prev.x < curr.x ? prev : curr;
    });

    let max = series.reduce(function (prev, curr) {
      return prev.x > curr.x ? prev : curr;
    });

    let xAxis = [];

    for (let i = max.x; i >= min.x; i -= granularity) {
      xAxis = xAxis.concat(i.toFixed(yAxisDecimalPlaces));
    }

    let maxLength = String(max.x).length;
    let titleLength = Math.round(title.length / 2);
    let titleSpacing = serieslen / 2 - titleLength;

    let graphTop = " ".repeat(maxLength) + "Y" + "-".repeat(serieslen) + " ";
    let graphTitle =
      title.length % 2 === 0
        ? " ".repeat(maxLength) +
          "|" +
          " ".repeat(titleSpacing) +
          title +
          " ".repeat(titleSpacing) +
          "|"
        : " ".repeat(maxLength) +
          "|" +
          " ".repeat(titleSpacing) +
          title +
          " ".repeat(titleSpacing + 1) +
          "|";

    let graphSpacer = " ".repeat(maxLength) + "|" + "-".repeat(serieslen) + "|";

    let graphDataHolder = xAxis.map((item) => {
      return { xAxis: item, arr: " ".repeat(serieslen).split("") };
    });

    let graphLine = [];

    for (let i = 0; i < serieslen; i++) {
      let plotPoint = series[i].x;

      let closest = graphDataHolder.reduce((a, b) => {
        return Math.abs(b.xAxis - plotPoint) < Math.abs(a.xAxis - plotPoint)
          ? b
          : a;
      });

      let closestValIndex = graphDataHolder.indexOf(closest);

      graphDataHolder[closestValIndex].arr[i] = "x";
    }

    graphDataHolder.map((item, index) => {
      let arrayToString = item.arr.join("");
      let labelLength = String(item.xAxis).length;
      let xlabelSpacer = maxLength - labelLength;
      let xlabel = " ".repeat(xlabelSpacer) + item.xAxis;

      graphLine.push(xlabel + "|" + arrayToString + "|");
    });

    let graphYaxis = " ".repeat(maxLength) + "|" + " ".repeat(serieslen) + "|";
    let graphBot = " ".repeat(maxLength) + " " + "-".repeat(serieslen) + "X";

    setGraphArray([
      graphTop,
      graphTitle,
      graphSpacer,
      ...graphLine,
      graphSpacer,
      graphYaxis,
      graphBot,
    ]);
  };

  return (
    <>
      <div className={`graph-holder ${theme}`} style={size} >
        {graphArray.length > 0 &&
          graphArray.map((item, index) => {
            return (
              <pre>
                <div className="noSelect" key={index}>{item}</div>
              </pre>
            );
          })}
      </div>
    </>
  );
}

export default LineGraph;
