// external imports
import React from "react";

// component imports
import FormInput from "../../GenericComponents/FormInput/FormInput";
import FormButton from "../../GenericComponents/FormButton/FormButton";
import "./StrategiesForm.css";

function StrategiesForm(props) {
  const { getStrategies } = props;

  // const inputProps = {
  //   id: "ticker",
  //   lightbackground: "true",
  //   name: "ticker",
  //   type: "text",
  //   placeholder: "ticker",
  //   errorMessage: "Ticker should be one to four characters",
  //   pattern: "^[a-zA-Z0-9^]{1,4}$",
  //   // label: "Add ticker",
  //   required: true,
  // };

  return (
    <form
      className={"form-transparent"}
      onSubmit={(value) => getStrategies(value, "create")}
    >
      {/* <FormInput
        {...inputProps}
        value={newTicker.name}
        onChange={(e) => {
          setNewTicker({ ...newTicker, name: e.target.value });
        }}
      /> */}
      {/* <FormButton buttonText={"Create"} /> */}
      <div className="thin-button-div">
        <button className="thin-button">Get Strategies</button>
      </div>
    </form>
  );
}

export default StrategiesForm;
