// external imports
import React, { useState, useContext } from "react";
import FormInput from "../../GenericComponents/FormInput/FormInput";
import * as BsIcons from "react-icons/bs";

// component imports
import "./LoginForm.css";
import FormButton from "../../GenericComponents/FormButton/FormButton";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";

function LoginForm(props) {
  const { theme } = useContext(ThemeContext);
  const [values, setValues] = useState({
    username: "",
    password: "",
    apikey: "",
  });

  const inputs = [
    {
      id: 1,
      name: "username",
      type: "text",
      placeholder: "username",
      errorMessage:
        "Username should be 3-16 characters and shouldn't include special characters.",
      pattern: "^[a-zA-Z0-9]{3,16}$",
      label: "Username",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "password",
      errorMessage:
        "Password should be 8-20 characters and should include atleast 1 letter and 1 number.",
      label: "Password",
      pattern: "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,20}$",
      required: true,
    },
    {
      id: 3,
      name: "apikey",
      type: "text",
      placeholder: "api key",
      errorMessage: "You must enter an API key.",
      label: "Api key",
      // pattern: "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,20}$",
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    props.loginUser(values);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={handleSubmit} className={`form ${theme}`}>
      <div className="login-icon">
        <BsIcons.BsKeyFill color="F7A90D" size={130}></BsIcons.BsKeyFill>
      </div>
      {inputs.map((input) => (
        <FormInput
          key={input.id}
          {...input}
          value={values[input.name]}
          onChange={onChange}
          lightbackground={"true"}
        />
      ))}
      <FormButton buttonText="Login"></FormButton>
    </form>
  );
}

export default LoginForm;
