import React, { useState, useEffect, useRef, cloneElement, useContext } from "react";
import { ThemeContext } from "../ThemeContext/ThemeContext";

import "./DraggableDiv.css";

function WithDraggable(props) {
  const { childComponent, containerRef, resizeable } = props;
  const boxRef = useRef(null);
  const isClicked = useRef(false);
  const [graphScale, setGraphScale] = useState(8)
  const {theme} = useContext(ThemeContext)

  const coords = useRef({
    startX: 0,
    startY: 0,
    lastX: 0,
    lastY: 0,
  });

  useEffect(() => {
    if (!boxRef.current || !containerRef.current) return;

    const box = boxRef.current;
    const container = containerRef.current;


    const onMouseDown = (e) => {
      isClicked.current = true;
      coords.current.startX = e.clientX;
      coords.current.startY = e.clientY;
    };

    const onMouseUp = (e) => {
      isClicked.current = false;
      coords.current.lastX = box.offsetLeft;
      coords.current.lastY = box.offsetTop;
    };

    const onMouseMove = (e) => {
      if (!isClicked.current) return;

      const nextX = e.clientX - coords.current.startX + coords.current.lastX;
      const nextY = e.clientY - coords.current.startY + coords.current.lastY;

      box.style.top = `${nextY}px`;
      box.style.left = `${nextX}px`;
    };

    box.addEventListener("mousedown", onMouseDown);
    box.addEventListener("mouseup", onMouseUp);
    container.addEventListener("mousemove", onMouseMove);
    container.addEventListener("mouseleave", onMouseUp);

    const cleanup = () => {
      box.removeEventListener("mousedown", onMouseDown);
      box.removeEventListener("mouseup", onMouseUp);
      container.removeEventListener("mousemove", onMouseMove);
      container.removeEventListener("mouseleave", onMouseUp);
    };

    return cleanup;
  }, []);

  return (
      <div ref={boxRef} className="box">
        {cloneElement(childComponent,{ scale: graphScale})}
        <div className={`toolbar`}>
          {resizeable && <>
          <button className={`plus-button ${theme}`} onClick={() => {setGraphScale(graphScale+1)}}>+</button>
          <button className={`minus-button ${theme}`} onClick={() => {setGraphScale(graphScale-1)}}>-</button>     
          </>}
          </div>
      </div>
  );
}

export default WithDraggable;
