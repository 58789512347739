import React, { useState, useEffect, useRef, useContext } from "react";
import { ThemeContext } from "../../GenericComponents/ThemeContext/ThemeContext";
import "./CaptchaRobot.css"

function CaptchaRobot(props) {
  const { setNotARobotComplete, getCaptcha } = props;
  const {theme} = useContext(ThemeContext)
  const firstPing = useRef(true);
  const timerCleanUp = useRef(null);
  const robotCleanUp = useRef(null);
  const [checked, setChecked] = useState(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);

  useEffect(() => {
    if (!displayAnimation) return;

    if (firstPing.current) {
      firstPing.current = false;
      robotCleanUp.current = setTimeout(() => {
        setNotARobotComplete(true);
      }, 1100);
      clearTimeout(timerCleanUp.current);
      getCaptcha();
    }
  }, [displayAnimation]);

  const checker = () => {
    setChecked(!checked);
    timerCleanUp.current = setTimeout(() => {
      setDisplayAnimation(true);
    }, 400);
  };

  return (
    <div className="cap-robo-wrap">
      <div className="check-line" />
      {displayAnimation ? (
        <div className="cap-first-half-ani">
          <svg
            className="check-svg"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
            height="50px"
          >
            <circle
              className="path circle"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeMiterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              className="path check"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
        </div>
      ) : (
        <div className="cap-first-half">
          <label className={`container ${theme}`}>
            <input
              type="checkbox"
              checked={checked}
              onChange={() => checker()}
            />
            <span className={`checkmark ${theme}`}></span>
          </label>
        </div>
      )}
      <div className={`cap-second-half ${theme}`}>I'm not a robot</div>
      <div className="check-line" />
    </div>
  );
}

export default CaptchaRobot;
